<div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0" *transloco="let t;">
  <form
    class="relative transform overflow-hidden rounded-[30px] bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6"
    (ngSubmit)="onSubmit()"
  >
    <div class="flex justify-between">
      <div class="text-center">
        <h3 class="text-xl font-semibold leading-6 text-prose" id="modal-title">{{ t('licence.licence') }}</h3>
      </div>

      <button class="flex items-baseline" type="button" (click)="dismiss()">
        <fa-icon [icon]="['fal', 'times']" size="lg" [fixedWidth]="true"></fa-icon>
      </button>
    </div>

    <div class="my-3">
      <label class="block font-medium leading-6 text-prose" for="licence">{{ t('licence.licenceenter') }}</label>
      <div class="mt-2">
        <input
          class="block w-full rounded-full border-0 p-3 text-prose shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey focus:ring-2 focus:ring-inset focus:ring-indigo-600"
          id="licence"
          name="licence"
          type="text"
          [placeholder]="t('licence.licence')"
          [(ngModel)]="keyInput"
          required
          #licence
        />
      </div>
    </div>

    <div class="mt-5 sm:mt-6">
      <button
        class="s8l-button s8l-b-clear inline-flex w-full justify-center shadow-md"
        [disabled]="!keyInput || keyInput.length === 0"
        type="submit"
      >
        {{ t('app.continue') }}
      </button>
    </div>
  </form>
</div>
