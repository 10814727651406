import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Log, Path, Tree, TreeConfig, TreeNode, TreeResource } from '@s8l/client-tree-lib';

import { environment } from 'src/environments/environment';

import { EnviromentService } from './environment.service';
import { ContentTreeServerService } from './jsonrpc/content-tree-server.service';
import { WikiServerService } from './jsonrpc/wiki-server.service';
import { Subscription } from 'rxjs';

export class ClientTreeNode extends TreeNode {
  public description: string;
}

@Injectable({
  providedIn: 'root'
})
export class TreeService {
  public rootNode: string;
  public config: TreeConfig;

  private _tree: Tree<ClientTreeNode>;
  private _treeLanguage: string;
  private _treeUpdates = new EventEmitter<any>();

  private _playableRounds = [];
  private _descriptions: { [uuid: string]: string } = {};

  private _subscription: Subscription;

  constructor(
    private env: EnviromentService,
    private router: Router,
    private content: ContentTreeServerService,
    private wiki: WikiServerService
  ) {
    this._subscription = this.env.languageUpdate.subscribe(lang => {
      const needsUpdate = this._treeLanguage != lang;
      if (!needsUpdate || !this.rootNode) {
        return;
      }

      Log.log('TreeService', 'language update', lang);

      void this.content
        .nodeRecursive(Path.parse(this.rootNode).uri())
        .then(data => this.updateTree(data))
        .then(() => {
          const oldShouldReuseRoute = this.router.routeReuseStrategy.shouldReuseRoute;
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.navigated = false;
          return this.router.navigate(['/']).then(() => (this.router.routeReuseStrategy.shouldReuseRoute = oldShouldReuseRoute));
        });
    });
  }

  public async init() {
    Log.log('TreeService', 'INIT');

    this.updateInfo();

    const tree = await this.content.nodeRecursive(Path.parse(this.rootNode).uri());
    await this.updateTree(tree);
  }

  public deinit() {
    this._tree = null;
    this._subscription?.unsubscribe();
  }

  public get root() {
    return this._tree?.root;
  }

  public get treeUpdates() {
    return this._treeUpdates.asObservable();
  }

  public get playableRounds() {
    if (this._playableRounds.length == 0) {
      this._playableRounds = this.getPlayables(this.root);
    }
    return this._playableRounds;
  }

  private _colorPalette = [];
  public get colorPalette() {
    return this._colorPalette;
  }

  public get(path: Path): ClientTreeNode {
    return this._tree.get(path);
  }

  public getByUUID(uuid: string): ClientTreeNode {
    return this._tree.getByUUID(uuid);
  }

  public set(path: Path, val: any): void {
    if (!this.isTreeNode(val)) {
      val = new TreeNode(val);
    }
    return this._tree.set(path, val);
  }

  private isTreeNode(val: any): val is TreeNode {
    return (val as TreeNode).hasResource !== undefined;
  }

  private updateInfo() {
    this.rootNode = environment.tree.root_node;
    this.config = new TreeConfig(environment.tree.modifiers, environment.tree.rules, environment.tree.templates);

    const style = getComputedStyle(document.documentElement);
    for (let i = 1; i < 11; i++) {
      const val = style.getPropertyValue(`--custom-color-${i}`);
      this._colorPalette.push(val);
    }
  }

  private async updateDescriptions() {
    const entries = await this.wiki.wikiGetChildrenRaw('description', {
      path: Path.parse(this.rootNode).uri(),
      recursive: true
    });
    for (const e of entries) {
      this._descriptions[e.uuid as string] = e?.fields?.content?.value || '';
    }
  }

  private injectNode(raw: any): ClientTreeNode {
    const res = new ClientTreeNode(raw, raw => this.injectNode(raw));
    res.description = this._descriptions[res.uuid];
    return res;
  }

  private async updateTree(tree: any) {
    await this.updateDescriptions();

    this._tree = new Tree<ClientTreeNode>(tree, raw => this.injectNode(raw));
    this._treeLanguage = Path.defaultLanguage;
    this._treeUpdates.emit(this.root);
  }

  public getRandomPlayableRoundPath() {
    return this.playableRounds[Math.floor(Math.random() * this.playableRounds.length)];
  }

  private _getPlayables(playables: any[], segment: TreeNode) {
    for (let i = 0; i < segment.children.length; i++) {
      const child = segment.children[i];

      if (child.children && child.children.length > 0) {
        playables = [...playables, ...this._getPlayables(playables, child)];
      }

      if (child.resources.includes(TreeResource.Quiz)) {
        playables = [...playables, child.path];
      }
    }

    return playables;
  }

  public getPlayables(segment: TreeNode) {
    return this._getPlayables([], segment);
  }
}
