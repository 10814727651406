import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AppFeatures, Path, TreeMediaFiles, TreeResource } from '@s8l/client-tree-lib';
import { Subscription } from 'rxjs';

import { LayoutType } from 'src/app/models/menu';
import { Methods } from 'src/app/models/methods';
import { AppLifecycleService } from 'src/app/services/app-lifecycle.service';
import { AppRouterService } from 'src/app/services/app-router.service';
import { EnviromentService } from 'src/app/services/environment.service';
import { ProfileService } from 'src/app/services/profile.service';

import { SidemenuService } from './sidemenu.service';
import { TreeService } from '../../services/tree.service';

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss']
})
export class SidemenuComponent implements OnInit, OnDestroy {
  @Input() public showSidebar = false;

  public user: any;
  public userFullname: string;
  public username: string;
  public items = [];
  public showBookmarks = false;
  public features: { [name: string]: boolean } = {
    licenceScan: this.env.hasFeature(AppFeatures.LicenceScan),
    search: this.env.hasFeature(AppFeatures.Search),
    bookmarks: this.env.hasFeature(AppFeatures.Bookmarks),
    journey: this.env.hasFeature(AppFeatures.Journey),
    ranking: this.env.hasFeature(AppFeatures.QuizRanking)
  };
  private treeSubscription: Subscription;

  constructor(
    public svc: SidemenuService,
    private tree: TreeService,
    private env: EnviromentService,
    private appRouter: AppRouterService,
    private profile: ProfileService,
    private lifecycle: AppLifecycleService
  ) {}

  public ngOnInit(): void {
    this.initSidemenu();
    this.treeSubscription = this.tree.treeUpdates.subscribe(() => {
      this.initSidemenu();
    });
    this.user = { ...this.profile.settings, ...this.profile.meta, uuid: this.profile.uuid };

    if (this.user.firstname && this.user.lastname) {
      this.userFullname = <string>this.user.firstname + ' ' + <string>this.user.lastname;
      this.username = '@' + <string>this.user.alias;
    } else {
      this.userFullname = this.user.alias;
      this.username = '';
    }
  }

  public ngOnDestroy() {
    if (this.treeSubscription) {
      this.treeSubscription.unsubscribe();
      this.treeSubscription = null;
    }
  }

  public initSidemenu() {
    if (!this.tree.root || !this.tree.root.children) {
      return;
    }

    const sideMenuItems = this.tree.root.children.filter(el => this.tree.config.matchesModifiers(el, ['sidemenu']));
    if (sideMenuItems.length <= 0) {
      return;
    }

    this.items = [];

    for (const item of sideMenuItems) {
      for (const child of item.children) {
        const hasWiki = child.resources.filter(r => r === 'WIKI').length > 0;
        if (child.name != 'Imprint' && hasWiki) {
          this.items.push({
            ...child,
            treeNode: true
          });
        }

        if (child.hasResource(TreeResource.Media)) {
          if (this.env.hasFeature(AppFeatures.Document)) {
            const hasDocuments = child.meta.mediafiles.find(m => m == TreeMediaFiles.Document);
            if (hasDocuments) {
              const idx = this.items.findIndex(i => i.uuid == child.uuid);
              if (idx >= 0) {
                this.items[idx].document = true;
              } else {
                this.items.push({
                  ...child,
                  document: true
                });
              }
            }
          }

          if (this.env.hasFeature(AppFeatures.Video)) {
            const hasVideos = child.meta.mediafiles.find(m => m == TreeMediaFiles.Video);
            if (hasVideos) {
              const idx = this.items.findIndex(i => i.uuid == child.uuid);
              if (idx >= 0) {
                this.items[idx].video = true;
              } else {
                this.items.push({
                  ...child,
                  video: true
                });
              }
            }
          }

          if (this.env.hasFeature(AppFeatures.Podcast)) {
            const hasPodcasts = child.meta.mediafiles.find(m => m == TreeMediaFiles.Podcast);
            if (hasPodcasts) {
              const idx = this.items.findIndex(i => i.uuid == child.uuid);
              if (idx >= 0) {
                this.items[idx].podcast = true;
              } else {
                this.items.push({
                  ...child,
                  podcast: true
                });
              }
            }
          }
        }
      }
    }

    if (this.env.hasFeature(AppFeatures.Evaluation)) {
      this.items.push({ name: 'Evaluation CAS', path: '/evaluation', treeNode: false });
    }
  }

  public open(item, preferDocument = false, preferVideo = false, preferPodcast = false) {
    this.svc.hide();
    if (!item.treeNode && !item.document) {
      return this.appRouter.navigateRaw([item.path]);
    }
    if (item.document && !preferPodcast && !preferVideo && (!item.treeNode || preferDocument)) {
      const path = Path.parse(item.path).removeFirst();
      return this.appRouter.navigate({ ...Methods.Document, path }, { layout: LayoutType.App });
    }
    if (item.video && !preferDocument && !preferPodcast && (!item.treeNode || preferVideo)) {
      const path = Path.parse(item.path).removeFirst();
      return this.appRouter.navigate({ ...Methods.Video, path }, { layout: LayoutType.App });
    }
    if (item.podcast && !preferDocument && !preferVideo && (!item.treeNode || preferPodcast)) {
      const path = Path.parse(item.path).removeFirst();
      return this.appRouter.navigate({ ...Methods.Podcast, path }, { layout: LayoutType.App });
    }
    const path = Path.parse(item.path).removeFirst();
    return this.appRouter.navigate({ ...Methods.WikiArticle, path }, { layout: LayoutType.App });
  }

  public openLegal() {
    this.svc.hide();
    return this.appRouter.navigateRaw(['/wiki/legal']);
  }

  public toggleBookmarks() {
    this.showBookmarks = !this.showBookmarks;
  }

  public async openImprint() {
    const path = new Path(['Sidemenu', 'Imprint']);
    const node = this.tree.get(path);

    if (!node || !node.resources.includes(TreeResource.Wiki)) {
      this.svc.hide();
      return this.appRouter.navigateRaw(['/imprint']);
    }
    this.svc.hide();
    return this.appRouter.navigate({ ...Methods.WikiArticle, path }, { layout: LayoutType.App });
  }

  public async navigate(url: any[]) {
    this.svc.hide();
    return this.appRouter.navigateRaw(url);
  }

  public signout() {
    this.svc.hide();
    return this.lifecycle.deinit();
  }
}
