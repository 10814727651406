<div
  class="h-screen-fix scrollbar-hide fixed right-0 top-0 z-40 flex w-0 flex-col justify-between overflow-y-auto bg-page shadow-lg transition-[width] duration-300 ease-in-out"
  [class.!w-80]="svc.shown | async"
  *transloco="let t;"
>
  <div class="mx-4 my-6 flex flex-col" *ngIf="svc.shown | async">
    <div class="flex items-center">
      <app-profile-icon class="mr-3 w-2/5" *ngIf="user" [seed]="user.uuid"></app-profile-icon>
      <div class="my-5 grow">
        <div class="flex w-full justify-end px-2 pb-4 sm:px-4">
          <app-language-switch></app-language-switch>
        </div>
        <h2 class="mb-0">{{ userFullname }}</h2>
      </div>
    </div>
    <div *ngIf="!showBookmarks; else bookmarks">
      <div class="my-4 flex flex-col justify-center">
        <button class="s8l-button s8l-b-clear my-2 w-full shadow" (click)="navigate(['/dashboard'])">
          <fa-icon class="text-2xl" [icon]="['fal','tachometer-fast']"></fa-icon> {{t('dashboard.title')}}
        </button>
        <button
          class="s8l-button s8l-b-clear my-2 w-full shadow"
          aria-label="search"
          *ngIf="features.search"
          (click)="navigate(['/wiki/search'])"
        >
          <fa-icon [icon]="['fal','search']" size="lg" [fixedWidth]="true"></fa-icon> {{t('app.search')}}
        </button>
        <button
          class="s8l-button s8l-b-clear my-2 w-full shadow"
          *ngIf="features.bookmarks"
          aria-label="bookmarks"
          (click)="toggleBookmarks()"
        >
          <fa-icon [icon]="['fal','bookmark']" size="lg" [fixedWidth]="true"></fa-icon> {{t('app.bookmarks')}}
        </button>
        <button class="s8l-button s8l-b-clear my-2 w-full shadow" *ngIf="features.journey" (click)="navigate(['user','journey'])">
          <fa-icon class="text-2xl" [icon]="['fal','history']"></fa-icon> {{t('app.journey')}}
        </button>
        <button class="s8l-button s8l-b-clear my-2 w-full shadow" *ngIf="features.ranking" (click)="navigate(['user','ranking'])">
          <fa-icon class="text-2xl" [icon]="['fal','trophy']"></fa-icon> {{t('app.ranking')}}
        </button>
        <button class="s8l-button s8l-b-clear my-2 w-full shadow" *ngIf="features.licenceScan" (click)="navigate(['license-scan'])">
          <fa-icon class="text-2xl" [icon]="['fal','qrcode']"></fa-icon> {{t('app.scanner')}}
        </button>
      </div>

      <h2 class="mt-8" *ngIf="items && items.length > 0">{{t('sidemenu.additionalContent')}}</h2>
      <button class="s8l-button s8l-b-clear mb-4 w-full shadow" *ngFor="let item of items" (click)="open(item)">
        <fa-icon
          *ngIf="item.document"
          (click)="open(item, true)"
          fill="clear"
          slot="end"
          [icon]="['fal','file']"
          [fixedWidth]="true"
        ></fa-icon>
        <fa-icon
          *ngIf="item.video"
          (click)="open(item, false, true)"
          fill="clear"
          slot="end"
          [icon]="['fal','video']"
          [fixedWidth]="true"
        ></fa-icon>
        <fa-icon
          *ngIf="item.podcast"
          (click)="open(item, false, false, true)"
          fill="clear"
          slot="end"
          [icon]="['fal','podcast']"
          [fixedWidth]="true"
        ></fa-icon>
        {{item.name}}
      </button>
    </div>
  </div>
  <div class="mb-4 flex justify-center" *ngIf="svc.shown | async">
    <button class="s8l-button s8l-b-clear w-1/2 shadow" (click)="openImprint()">
      <fa-icon class="text-2xl" start [icon]="['fal','info-circle']"></fa-icon> {{t('app.imprint')}}
    </button>
    <button class="s8l-button s8l-b-clear w-1/2 text-danger shadow" (click)="signout()">
      <fa-icon class="mr-3" class="text-2xl" start [icon]="['fal', 'sign-out']"></fa-icon>{{t('app.logout')}}
    </button>
  </div>
</div>

<!-- body overlay -->
<div class="h-screen-fix absolute left-0 top-0 z-30 w-screen bg-black opacity-50" *ngIf="svc.shown | async" (click)="svc.hide()"></div>

<ng-template #bookmarks>
  <button class="s8l-button s8l-b-clear my-2 w-full shadow" *transloco="let t;" aria-label="bookmarks" (click)="toggleBookmarks()">
    <fa-icon [icon]="['fal','chevron-left']" size="lg" [fixedWidth]="true"></fa-icon> {{t('app.back')}}
  </button>
  <dashboard-bookmark class="mt-4"></dashboard-bookmark>
</ng-template>
