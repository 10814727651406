import { Component, Input } from '@angular/core';

import { AppRouterService } from 'src/app/services/app-router.service';

export interface FooterButton {
  enabled: boolean;
  text: string;
  icon: string;
  link: string[];
}

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  @Input() public buttons: FooterButton[] = [];

  public open = false;
  public closing = false;
  public menuTimeout;

  public get enabledButtons(): FooterButton[] {
    return this.buttons.filter(x => x.enabled);
  }

  public get footerButtons(): FooterButton[] {
    if (!this.menu) return this.enabledButtons;

    return this.enabledButtons.slice(0, 3);
  }

  public get menu(): boolean {
    if (this.orientation == 'landscape') {
      return false;
    }
    return screen.width <= 490 && this.enabledButtons.length > 3;
  }

  public get orientation() {
    const orientation = screen?.orientation?.type;
    if (orientation) {
      if (orientation == 'landscape-primary' || orientation == 'landscape-secondary') {
        return 'landscape';
      }
      return 'portrait';
    }

    const mql = window.matchMedia('(orientation: portrait)');
    if (mql.matches) {
      return 'portrait';
    }
    return 'landscape';
  }

  constructor(public router: AppRouterService) {}

  public navigate(button: FooterButton) {
    return this.router.navigateRaw(button.link);
  }

  public openMenu() {
    this.open = true;
  }

  public closeMenu() {
    this.closing = true;

    if (this.menuTimeout) {
      window.clearTimeout(this.menuTimeout);
    }

    this.menuTimeout = window.setTimeout(() => {
      this.closing = false;
      this.open = false;
    }, 150);
  }
}
