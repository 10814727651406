import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService, JSONRPCError, JSONRPCService } from '@s8l/client-tree-lib';

import { EnviromentService } from '../environment.service';

export interface RoleRoleGetRequest {
  name?: string;
}

export interface RoleRole {
  name?: string;
  description?: string;
  created?: RoleInstant;
  updated?: RoleInstant;
}

export type RoleInstant = any;

export interface RoleRoleUpsertRequest {
  name?: string;
  description?: string;
}

export type UserSecondFactor = any;
export type UserInstant = any;

export interface UserExpiration {
  timestamp?: UserInstant;
  duration?: UserPeriodDuration;
}

export interface UserPeriodDuration {
  period?: UserPeriod;
  duration?: UserDuration;
}

export type UserPeriod = any;

export type UserDuration = any;

export interface UserUserRequest {
  password?: string;
  profile?: UserUserProfile;
}

export interface UserUserProfile {
  uuid?: string;
  username?: string;
  meta?: UserAbstractMetadata;
  labels?: any;
  settings?: any;
  enabled?: boolean;
}

export interface UserAbstractMetadata {
  mail?: string;
  roles?: string[];
  confirmation?: UserSecondFactor;
}

export interface EventPageRequest {
  events?: EventPageEvent[];
}

export interface EventPageEvent {
  type?: string;
  page?: string;
  path?: string;
  meta?: any;
  timestamp?: number;
}

export interface EventObject {
  _children?: any;
  _nodeFactory?: EventJsonNodeFactory;
}

export interface EventJsonNodeFactory {
  _cfgBigDecimalExact?: boolean;
}

export interface EventPageResponse {
  count?: number;
}

export interface TemplateLicenceRequest {
  key?: string;
  licence?: TemplateTemplateLicence;
}

export interface TemplateTemplateLicence {
  key?: string;
  volume?: number;
  activations?: number;
  description?: string;
  username?: string;
  pattern?: string;
  precedence?: number;
  expiration?: TemplateInstant;
  created?: TemplateInstant;
  updated?: TemplateInstant;
  profile?: TemplateTemplateProfile;
}

export type TemplateInstant = any;

export interface TemplateTemplateProfile {
  meta?: TemplateAbstractMetadata;
  labels?: any;
  settings?: any;
  enabled?: boolean;
}

export interface TemplateAbstractMetadata {
  mail?: string;
  roles?: string[];
  confirmation?: TemplateSecondFactor;
}

export type TemplateSecondFactor = any;

export interface TemplateWhitelistRequest {
  username?: string;
  pattern?: string;
  template?: TemplateTemplate;
}

export interface TemplateTemplate {
  username?: string;
  pattern?: string;
  precedence?: number;
  expiration?: TemplateInstant;
  created?: TemplateInstant;
  updated?: TemplateInstant;
  profile?: TemplateTemplateProfile;
}

export interface PropertiesPropertyEntry {
  group?: string;
  key?: string;
  value?: any;
}

export interface SettingsUserProfile {
  uuid?: string;
  username?: string;
  meta?: SettingsAbstractMetadata;
  labels?: any;
  settings?: any;
  enabled?: boolean;
}

export interface SettingsAbstractMetadata {
  mail?: string;
  roles?: string[];
  confirmation?: SettingsSecondFactor;
}

export type SettingsSecondFactor = any;

export interface PermissionsPermissionRequest {
  role?: string;
  permission?: PermissionsPermission[];
}

export interface PermissionsPermission {
  subject?: PermissionsSubject;
  verb?: PermissionsVerb;
}

export interface PermissionsSubject {
  base?: string;
  sub?: string;
  node?: string;
}

export type PermissionsVerb = any;

export interface ProfileUserProfile {
  uuid?: string;
  username?: string;
  meta?: ProfileAbstractMetadata;
  labels?: any;
  settings?: any;
  enabled?: boolean;
}

export interface ProfileAbstractMetadata {
  mail?: string;
  roles?: string[];
  confirmation?: ProfileSecondFactor;
}

export type ProfileSecondFactor = any;

export interface AccountPasswordRequest {
  username?: string;
  newPassword?: string;
  currentPassword?: string;
  token?: string;
}

export interface AccountResetRequest {
  username?: string;
}

export interface AccountLoginRequest {
  app?: string;
  username?: string;
  password?: string;
  device?: DeviceInformation;
  token?: string;
}

export interface DeviceInformation {
  id?: string;
  platform?: string;
}

export interface AccountAuthResponse {
  token?: string;
  profile?: AccountUserProfile;
}

export interface AccountUserProfile {
  uuid?: string;
  username?: string;
  meta?: AccountAbstractMetadata;
  labels?: any;
  settings?: any;
  enabled?: boolean;
}

export interface AccountAbstractMetadata {
  mail?: string;
  roles?: string[];
  licences?: AccountLicenceActivation[];
  confirmation?: AccountSecondFactor;
}

export type ActivationInstant = any;
export type ActivationDuration = any;

export interface AccountLicenceActivation {
  key?: string;
  active?: boolean;
  roles?: string[];
  activations?: number;
  activation?: ActivationInstant;
  expiration?: ActivationInstant;
  duration?: ActivationDuration;
}

export type AccountSecondFactor = any;

export interface AccountRegisterInfoRequest {
  username?: string;
  licence?: string;
}

export interface AccountRegisterInfoResponse {
  registered?: boolean;
  confirmation?: UserSecondFactor;
}

export interface AccountPasswordRule {
  pattern: string;
  match: boolean;
  error: JSONRPCError;
}

export interface AccountRegisterRequest {
  username?: string;
  password?: string;
  licence?: string;
  token?: string;
}

export interface LicenceActivateRequest {
  licence?: string;
}

export interface SamlConfigResponse {
  id?: string;
  url?: string;
  payload?: string;
}

export enum JSONRPCUserErrorCode {
  ALREADY_EXISTS = 3011,
  LOGIN_ERROR = 3021,
  PASSWORD_INVALID = 3022,
  PROFILE_MISSING = 3031,
  PROFILE_EXPIRED = 3032,
  PROFILE_DISABLED = 3033,
  PROFILE_REGISTERED = 3034,
  REGISTER_FORBIDDEN = 3041,
  MAIL_REQUIRED = 3042,
  LICENCE_EXPIRED = 3043,
  LICENCE_DEPLETED = 3044,
  LICENCE_INVALID = 3045,
  CHALLENGE_REQUIRED = 3051,
  CHALLENGE_INVALID = 3061,
  CHALLENGE_EXPIRED = 3062,
  CHALLENGE_SOLVED = 3063,
  CHALLENGE_ERROR = 3064,
  PASSWORD_FORBIDDEN = 3121
}

@Injectable({
  providedIn: 'root'
})
export class UserServerService extends JSONRPCService {
  constructor(private env: EnviromentService, protected http: HttpClient, protected auth: AuthService) {
    super(http, auth);
    this.prefix = 'UserServerService';
  }

  public async eventPage(params: EventPageRequest): Promise<EventPageResponse> {
    return this.message(this.env.getServerUrl('USER_SERVER', `/event`), 'page', params);
  }

  public async propertiesAll(): Promise<any> {
    return this.message(this.env.getServerUrl('USER_SERVER', `/profile/properties`), 'all', null);
  }

  public async propertiesKeys(): Promise<any> {
    return this.message(this.env.getServerUrl('USER_SERVER', `/profile/properties`), 'keys', null);
  }

  public async propertiesGet(params: PropertiesPropertyEntry): Promise<any> {
    return this.message(this.env.getServerUrl('USER_SERVER', `/profile/properties`), 'get', params);
  }

  public async propertiesPut(params: PropertiesPropertyEntry): Promise<void> {
    return this.message(this.env.getServerUrl('USER_SERVER', `/profile/properties`), 'put', params);
  }

  public async settingsSet(params: any): Promise<SettingsUserProfile> {
    return this.message(this.env.getServerUrl('USER_SERVER', `/profile/settings`), 'set', params);
  }

  public async settingsGet(): Promise<any> {
    return this.message(this.env.getServerUrl('USER_SERVER', `/profile/settings`), 'get', null);
  }

  public async settingsPut(params: any): Promise<SettingsUserProfile> {
    return this.message(this.env.getServerUrl('USER_SERVER', `/profile/settings`), 'put', params);
  }

  public async profileGet(): Promise<ProfileUserProfile> {
    return this.message(this.env.getServerUrl('USER_SERVER', `/profile`), 'get', null);
  }

  public async accountPassword(params: AccountPasswordRequest): Promise<void> {
    return this.message(this.env.getServerUrl('USER_SERVER', `/account`), 'password', params);
  }

  public async accountLogout(): Promise<void> {
    return this.message(this.env.getServerUrl('USER_SERVER', `/account`), 'logout', null);
  }

  public async accountAlive(): Promise<void> {
    return this.message(this.env.getServerUrl('USER_SERVER', `/account`), 'alive', null);
  }

  public async accountRefresh(): Promise<AccountAuthResponse> {
    return this.message(this.env.getServerUrl('USER_SERVER', `/account`), 'refresh', null);
  }

  public async accountReset(params: AccountResetRequest): Promise<void> {
    return this.message(this.env.getServerUrl('USER_SERVER', `/account`), 'reset', params);
  }

  public async accountLogin(params: AccountLoginRequest): Promise<AccountAuthResponse> {
    return this.message(this.env.getServerUrl('USER_SERVER', `/account`), 'login', params);
  }

  public async accountDelete(): Promise<void> {
    return this.message(this.env.getServerUrl('USER_SERVER', `/account`), 'delete', null);
  }

  public async accountRegisterInfo(params: AccountRegisterInfoRequest): Promise<AccountRegisterInfoResponse> {
    return this.message(this.env.getServerUrl('USER_SERVER', `/account`), 'register-info', params);
  }

  public async accountPasswordRules(): Promise<AccountPasswordRule[]> {
    return this.message(this.env.getServerUrl('USER_SERVER', `/account`), 'password-rules', null);
  }

  public async accountRegister(params: AccountRegisterRequest): Promise<void> {
    return this.message(this.env.getServerUrl('USER_SERVER', `/account`), 'register', params);
  }

  public async licenceActivate(params: LicenceActivateRequest): Promise<AccountUserProfile> {
    return this.message(this.env.getServerUrl('USER_SERVER', `/licence`), 'activate', params);
  }

  public async samlConfig(): Promise<SamlConfigResponse> {
    return this.message(this.env.getServerUrl('USER_SERVER', `/saml`), 'config', null);
  }
}
