import { Component, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

import { PopupService } from 'src/app/modules/popup/popup.service';

@Component({
  selector: 'app-licence-modal',
  templateUrl: './licence-modal.component.html',
  styleUrls: ['./licence-modal.component.scss']
})
export class LicenceModalComponent implements AfterViewInit {
  @Input() public keyInput: string;
  @ViewChild('licence') public licenceInput: ElementRef;

  constructor(public pop: PopupService) {}

  public ngAfterViewInit() {
    this.focusOnInput();
  }

  private focusOnInput() {
    if (this.licenceInput) {
      this.licenceInput.nativeElement.focus();
    }
  }

  public onSubmit() {
    return this.pop.dismissModal({
      licence: this.keyInput
    });
  }

  public dismiss() {
    return this.pop.dismissModal();
  }
}
